import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgClass } from '@angular/common';
import { ListItemComponent } from '@angular-shared/components/list-item/list-item.component';
import { HighlightPipeModule } from '@utils/pipes/highlight-pipe.module';
import { GetDownloadUrlPipeModule } from '@utils/pipes/get-download-url-pipe.module';

@NgModule({
  declarations: [
    ListItemComponent,
  ],
  imports: [
    CommonModule,
    AsyncPipe,
    NgClass,
    HighlightPipeModule,
    GetDownloadUrlPipeModule,
  ],
  exports: [
    ListItemComponent,
  ],
})
export class NsListItemModule { }
