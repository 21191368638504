import { Component, Input, OnInit } from '@angular/core';
import { DomService } from '@services/dom.service';
import { ThemeService } from '@services/theme.service';
import { LanguageService } from '@services/language.service';

@Component({
  selector: 'ns-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  @Input() showProfileFallBackImage = false;
  @Input() hideThumbnail = false;
  @Input() searchResult: {
    title: string,
    subTitle: string,
    thumbnail: string
  };
  @Input() searchedText = '';
  @Input() category: 'nasheed' | 'artist' | 'album' | 'author';
  constructor(public domService: DomService,
    public themeService: ThemeService,
    public languageService: LanguageService) {
  }
  ngOnInit(): void {
  }
}
