@if ({
  darkThemeEnabled: themeService.darkThemeEnabled | async,
  currentLanguage: languageService.currentLanguage$ | async
}; as AsyncItems) {
  <div class="search-result-row">
    <ng-container
      [ngTemplateOutletContext]="{thumbnail: searchResult.thumbnail, showProfileFallBackImage: showProfileFallBackImage}"
      [ngTemplateOutlet]="showThumbnail">
    </ng-container>

    <div class="search-result-details">
              <span [innerHTML]="searchResult.title | highlight: searchedText : searchedText"
                    class="title"></span>
      @if (category !== 'artist') {
        <small class="subtitle">{{ searchResult.subTitle }}</small>
      }
    </div>
  </div>

  <ng-template #showThumbnail let-showProfileFallBackImage='showProfileFallBackImage' let-thumbnail='thumbnail'>
    @if (thumbnail | getDownloadUrl | async; as image) {
      <div [class]="AsyncItems.darkThemeEnabled ? 'thumbnail_bg_dark' : 'thumbnail_bg_light'"
           [style.background-image]="'url(' + image + ')'"
           class="thumbnail">
      </div>
    } @else if (!hideThumbnail) {
      @if (showProfileFallBackImage) {
        <div [class]="AsyncItems.darkThemeEnabled ? 'thumbnail_bg_dark' : 'thumbnail_bg_light'"
             [style.background-image]="'url(' + (domService.assetsDirectory + (AsyncItems.darkThemeEnabled ? 'profile_dark.svg': 'profile_light.svg')) + ')'"
             class="thumbnail">
        </div>
      } @else {
        <div [class]="AsyncItems.darkThemeEnabled ? 'thumbnail_bg_dark' : 'thumbnail_bg_light'"
             class="thumbnail">
          <div
            [style.background-image]="'url(' + (domService.assetsDirectory + (AsyncItems.darkThemeEnabled ? 'empty_poster_dark.svg': 'empty_poster_light.svg')) + ')'"
            class="fallbackNasheedImage"
          ></div>
        </div>
      }
    }
  </ng-template>
}
