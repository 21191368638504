import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
  transform(text: string, search: string, ctrlValue: string): string {
    if (typeof ctrlValue !== 'string') {
      return text;
    }
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');
    return (search && ctrlValue) ? text.replace(regex, (match) => `<b class='yellow'>${match}</b>`) : text;
  }
}
